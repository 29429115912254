import React, { Fragment, useState } from "react";
import UploadImage from "../../common/UploadImage";
import { Form, FormInput, FormGroup, FormCheckbox, Button } from "shards-react";

import { db } from "../../../firebase";
const AddGroupForm = (props) => {
  const group = props.group || {};
  const {
    prodid = "",
    nombre = "",
    orden = "",
    estado = false,
    imagen = "https://firebasestorage.googleapis.com/v0/b/petsvetlook.appspot.com/o/images%2Fdefault-photo.png?alt=media&token=a0aa99b5-f2d3-472f-89fc-a973225647b1",
  } = group;
  const editting = props.edit || false;

  const [state, setState] = useState(estado);
  const [photo, setPhoto] = useState(imagen);
  const [name, setName] = useState(nombre);
  const [order, setOrder] = useState(orden);
  // eslint-disable-next-line
  const [edit, setEdit] = React.useState(editting);
// eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
// eslint-disable-next-line
  const [alert, setAlert] = React.useState({});
  const [error, setError] = React.useState(null);
  // eslint-disable-next-line
  const [id, setId] = React.useState(prodid);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };
  // eslint-disable-next-line
  const handleStateChange = (event) => {
    setState(!state);
  };
  const handlePhotoChange = (event) => {
    setPhoto(event.target.value);
  };
// eslint-disable-next-line
  const onSubmit = (data) => {
    if (name !== "" && order !== "") {
      if (!editting) {
        db.collection("grupos")
          .doc(name.toUpperCase().replace(/ /g, "-"))
          .set({
            nombre: name.toUpperCase(),
            estado: state,
            imagen: photo,
            orden: order,
            prodid: name.toUpperCase().replace(/ /g, "-"),
          })
          .then(function() {
            setName("");
            setPhoto("");
            setOrder("");
            props.refresh();
            props.close();
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        db.collection("grupos")
          .doc(prodid)
          .update({
            estado: state,
            imagen: photo,
            orden: order,
          })
          .then(function() {
           // props.refresh();
            props.close();
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    } else {
      setError("Falta completar datos.");
      //errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Form>
        <FormGroup>
          <label htmlFor="name">Nombre</label>
          <FormInput
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Nombre del grupo"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="order">Orden</label>
          <FormInput
            className="form-control"
            name="order"
            type="text"
            value={order}
            onChange={handleOrderChange}
            placeholder="Orden"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="photo">
            Foto (Puede elegir una desde su computadora o ingresar un enlace)
          </label>
          <UploadImage name="images" setURL={setPhoto} />
          <FormInput
            className="form-control"
            name="photo"
            type="text"
            id="photo"
            placeholder="Seleccionar imagen"
            onChange={handlePhotoChange}
          />
        </FormGroup>
        <FormCheckbox
          name="state"
          toggle
          checked={state}
          onChange={handleStateChange}
        >
          Estado
        </FormCheckbox>
        <Button block theme="accent" onClick={onSubmit}>
          Guardar
        </Button>
        {error && <div className="alert alert-warning">{error}</div>}
      </Form>
    </Fragment>
  );
};

export default AddGroupForm;
