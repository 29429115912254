import React from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import BreedGallery from "../../components/wiki/BreedGallery";
import Loader from "../../components/Loader/Loader";
import { db } from "../../firebase";

import AddSpeciesForm from "../../components/wiki/forms/AddSpeciesForm";

import AddBreedForm from "../../components/wiki/forms/AddBreedForm";
import WikiModal from "../../components/common/WikiModal";

const WikiVetBreed = (props) => {
  const {
    match: {
      params: { grupoID, breed },
    },
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [breeds, setBreeds] = React.useState([]);

  const [editType, setEditType] = React.useState(false);
  const [addRace, setAddRace] = React.useState(false);
  const [type, setType] = React.useState(undefined);

  const getType = () => {
    db
      .collection("ESPECIES")
      .doc(breed)
      .get()
      .then((res) => {
        setType(res.data());
        getBreeds();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getType();
  }, []);
  

  const getBreeds = async () => {
    setLoading(true);
    db
      .collection("RAZAS")
      .where("especieID", "==", breed)
      .get()
      .then((res) => {
        const breeds = [];
        res.forEach((item) => {
          breeds.push(item.data());
        });
        setBreeds(breeds);
        setLoading(false);
      });
    
  };


  if (loading) {
    return <Loader />;
  } else {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Wiki de Vetlook"
            subtitle="Administrador Vetlook"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                    <h4>Selecciona una raza</h4>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6 col-sm-12 text-right">
                  <ButtonGroup>
                      <Button
                        outline
                        theme="info"
                        onClick={() => setEditType(!editType)}
                      >
                        Editar Especie
                      </Button>
                      <Button
                        outline
                        theme="success"
                        onClick={() => setAddRace(!addRace)}
                      >
                        Agregar Raza
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <BreedGallery wikiBreeds={breeds} grupoID={grupoID} />
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
        <WikiModal
          title={"Editar especie"}
          body={
            <AddSpeciesForm
              species={type}
              close={() => setEditType(!editType)}
              edit={true}
            />
          }
          open={editType}
          close={() => {
            setEditType(!editType);
          }}
        />
        <WikiModal
          title={"Agregar raza"}
          size={'lg'}
          body={
            <AddBreedForm
              species={type}
              close={() => setAddRace(!addRace)}
              edit={true}
              refresh={getBreeds}
            />
          }
          open={addRace}
          close={() => {
            setEditType(!addRace);
          }}
        />
      </Container>
    );
  }
};

export default WikiVetBreed;
