import React, { Fragment, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadImage from "../../common/UploadImage";
import { db } from "../../../firebase";

import { Form, FormInput, FormGroup, Button, Row, Col } from "shards-react";

const AddSpeciesModal = (props) => {
  // eslint-disable-next-line
  const [modal, setModal] = useState();
  // eslint-disable-next-line
  const [speciesId, setSpeciesId] = useState(props.species.id);
  const [photo, setPhoto] = useState(
    "https://firebasestorage.googleapis.com/v0/b/petsvetlook.appspot.com/o/images%2Fdefault-photo.png?alt=media&token=a0aa99b5-f2d3-472f-89fc-a973225647b1"
  );
  const [name, setName] = useState("");
  // eslint-disable-next-line
  const [order, setOrder] = useState("");

  const [content, setContent] = useState("");
  const [color, setColor] = useState("");
  const [life, setLife] = useState("");
  const [fur, setFur] = useState("");
  const [history, setHistory] = useState("");
  const [diagnoses, setDiagnoses] = useState("");

  //dog exclusive inputs
  const [functions, setFunctions] = useState("");
  const [alias, setAlias] = useState("");
  const [size, setSize] = useState("");
  // eslint-disable-next-line
  const species = props.species;

  const dogInputs = props.dog || false;
  // eslint-disable-next-line
  const handleColorChange = (value) => {
    setColor(value);
  };

  const handleLifeChange = (event) => {
    setLife(event.target.value);
  };
  const handleFurChange = (event) => {
    setFur(event.target.value);
  };
  // eslint-disable-next-line
  const handleHistoryChange = (value) => {
    setHistory(value);
  };
// eslint-disable-next-line
  const handleDiagnosesChange = (value) => {
    setDiagnoses(value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
// eslint-disable-next-line
  const handleContentChange = (value) => {
    setContent(value);
  };
  const handlePhotoChange = (event) => {
    setPhoto(event.target.value);
  };
// eslint-disable-next-line
  const handleFunctionsChange = (value) => {
    setFunctions(value);
  };
  const handleAliasChange = (event) => {
    setAlias(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };
// eslint-disable-next-line
  const onSubmit = (data) => {
    if (name !== "") {
      db.collection("RAZAS")
        .doc(name.toUpperCase().replace(/ /g, "-"))
        .set({
          imagen: photo,
          particularidades: content,
          alias: alias,
          pelaje: fur,
          funciones: functions,
          color: color,
          diagnosticos: diagnoses,
          vida: life,
          historia: history,
          size: size,
          nombre: name.toUpperCase(),
          id: name.toUpperCase().replace(/ /g, "-"),
          especieID: speciesId,
        })
        .then(() => {
          setName("");
          setPhoto("");
          setContent("");
          setLife("");
          setColor("");
          setHistory("");
          setDiagnoses("");
          setFur("");
          setAlias("");
          setSize("");
          props.refresh();
          props.close();
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      //errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Form>
        <FormGroup>
          <label htmlFor="name">Nombre</label>
          <FormInput
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Nombre de la raza"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="photo">
            Foto (Puede elegir una desde su computadora o ingresar un enlace)
          </label>
          <UploadImage name="images" setURL={setPhoto} />
          <FormInput
            className="form-control"
            name="photo"
            type="text"
            id="photo"
            placeholder="Seleccionar imagen"
            onChange={handlePhotoChange}
          />
        </FormGroup>
        <Row>
          <Col md="6" className="form-group">
            <FormGroup>
              <label htmlFor="order">Esperanza de Vida</label>
              <FormInput
                className="form-control"
                id="vida"
                placeholder="Esperanza de vida"
                type="text"
                name="vida"
                onChange={handleLifeChange}
                value={life}
              />
            </FormGroup>
          </Col>
          <Col md="6" className="form-group">
            <FormGroup>
              <label htmlFor="order">Pelaje</label>
              <FormInput
                className="form-control"
                id="fur"
                placeholder="Pelaje"
                type="text"
                name="fur"
                onChange={handleFurChange}
                value={fur}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label htmlFor="color">Color</label>
              <ReactQuill
                id="color"
                placeholder="color"
                name="color"
                theme="snow"
                value={color || ""}
                onChange={setColor}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label htmlFor="content">Particularidades</label>
              <ReactQuill
                id="content"
                placeholder="Particularidades"
                name="content"
                theme="snow"
                value={content || ""}
                onChange={setContent}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <label htmlFor="history">Historia</label>
          <ReactQuill
            id="history"
            placeholder="Historia"
            name="history"
            theme="snow"
            value={history || ""}
            onChange={setHistory}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="diagnosticos">Diagnósticos</label>
          <ReactQuill
            id="diagnosticos"
            placeholder="Diagnósticos"
            name="diagnosticos"
            theme="snow"
            value={diagnoses || ""}
            onChange={setDiagnoses}
          />
        </FormGroup>
        {dogInputs ? (
          <Fragment>
            <FormGroup>
              <label htmlFor="function">Función</label>
              <ReactQuill
                id="function"
                placeholder="Diagnósticos"
                name="function"
                theme="snow"
                value={functions || ""}
                onChange={setFunctions}
              />
            </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="size">Tamaño</label>
                  <FormInput
                    className="form-control"
                    id="size"
                    placeholder="Tamaño"
                    type="text"
                    name="size"
                    onChange={handleSizeChange}
                    value={size}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label htmlFor="alias">También conocido como</label>
                  <FormInput
                    className="form-control"
                    id="alias"
                    placeholder="También conocido como"
                    type="text"
                    name="alias"
                    onChange={handleAliasChange}
                    value={alias}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
        <Button block theme="accent" onClick={onSubmit}>
          Guardar
        </Button>
      </Form>
    </Fragment>
  );
};

export default AddSpeciesModal;
