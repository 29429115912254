import React, { Fragment, useState } from "react";

import UploadImage from "../../common/UploadImage";

import { Form, FormInput, FormGroup, Button } from "shards-react";

import { db } from "../../../firebase";

const AddSpeciesForm = (props) => {
  const species = props.species || {};
  const {
    id = "",
    nombre = "",
    ORDEN = "",
    // eslint-disable-next-line
    grupoID = "",
    imagen = "https://firebasestorage.googleapis.com/v0/b/petsvetlook.appspot.com/o/images%2Fdefault-photo.png?alt=media&token=a0aa99b5-f2d3-472f-89fc-a973225647b1",
  } = species;
  const editting = props.edit || false;
// eslint-disable-next-line
  const [especiesId, setId] = useState(id);
  const [groupId, setGroupId] = useState(props.group.prodid);
  const [photo, setPhoto] = useState(imagen);
  const [name, setName] = useState(nombre);
  const [order, setOrder] = useState(ORDEN);

  const [error, setError] = useState();
// eslint-disable-next-line
  const group = props.group;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.target.value);
  };
// eslint-disable-next-line
  const onSubmit = (data) => {
    if (name !== "" && order !== "") {
      if (!editting) {
        db.collection("ESPECIES")
          .doc(name.toUpperCase().replace(/ /g, "-"))
          .set({
            nombre: name,
            imagen: photo,
            grupoID: groupId,
            id: name.toUpperCase().replace(/ /g, "-"),
            ORDEN: order.toUpperCase(),
          })
          .then(function() {
            setName("");
            setPhoto("");
            setGroupId(0);
            props.refresh();
            props.close();
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        db.collection("ESPECIES")
          .doc(especiesId)
          .update({
            nombre: name,
            imagen: photo,
            ORDEN: order,
          })
          .then(function() {
            //props.refresh();
            props.close();
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    } else {
      setError("Falta completar datos.");
      //errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Form>
        <FormGroup>
          <label htmlFor="name">Nombre</label>
          <FormInput
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Nombre de la especie"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="order">Orden</label>
          <FormInput
            className="form-control"
            name="order"
            type="text"
            value={order}
            onChange={handleOrderChange}
            placeholder="Orden"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="photo">
            Foto (Puede elegir una desde su computadora o ingresar un enlace)
          </label>
          <UploadImage name="images" setURL={setPhoto} />
          <FormInput
            className="form-control"
            name="photo"
            type="text"
            id="photo"
            placeholder="Seleccionar imagen"
            onChange={handlePhotoChange}
          />
        </FormGroup>
        <Button block theme="accent" onClick={onSubmit}>
          Guardar
        </Button>
        {error && <div className="alert alert-warning">{error}</div>}
      </Form>
    </Fragment>
  );
};

export default AddSpeciesForm;
