import React, { useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import GroupsGallery from "../../components/wiki/GroupsGallery";
import WikiModal from "../../components/common/WikiModal";
import AddGroupForm from "../../components/wiki/forms/AddGroupForm";
import { db } from "../../firebase";

import Loader from "../../components/Loader/Loader";

const WikiVetGroups = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const groupA = a.orden.toUpperCase();
    const groupB = b.orden.toUpperCase();

    let comparison = 0;
    if (groupA > groupB) {
      comparison = 1;
    } else if (groupA < groupB) {
      comparison = -1;
    }
    return comparison;
  }

  const getGroups = () => {
    setLoading(true);
    db.collection("grupos")
      .get()
      .then((res) => {
        const groups = [];
        res.forEach((item) => {
          groups.push(item.data());
        });
        groups.sort(compare);
        setGroups(groups);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getGroups();
  }, []);

  const newGroup = () => {
    setOpen(!open);
  };
  if (loading) {
    return <Loader />;
  } else {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Wiki de Vetlook"
            subtitle="Administrador Vetlook"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h4>Selecciona un grupo</h4>
                  </div>
                  <div className="col-md-6 col-sm-12 text-right">
                    <ButtonGroup>
                      <Button
                        outline
                        theme="success"
                        onClick={() => setOpen(!open)}
                      >
                        Agregar Especie
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <GroupsGallery wikiGroups={groups} />
              </CardBody>
            </Card>
          </div>
        </Row>
        <WikiModal
          title={"Agregar nuevo grupo"}
          body={<AddGroupForm close={newGroup} refresh={getGroups} />}
          view="groups"
          open={open}
          close={() => {
            setOpen(!open);
          }}
        />
      </Container>
    );
  }
};

export default WikiVetGroups;
