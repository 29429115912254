import React, { Fragment, useState } from "react";
import ImageUploader from "react-images-upload";
import { storage } from "../../firebase";
const UploadImage = (props) => {
  // eslint-disable-next-line
  const [url, setUrl] = useState("");
  // Create a root reference
  const storageRef = storage.ref();
// eslint-disable-next-line
  const onDrop = (pictureFiles, pictureDataURLs) => {
    const file = pictureFiles[0];
    if (file) {
      // Image upload
      const ref = storageRef.child(props.name + "/" + file.name);
      ref.put(file).then(function(snapshot) {
        snapshot.ref.getDownloadURL().then(function(downloadURL) {
          props.setURL(downloadURL);
          setUrl(downloadURL);
        });
      });
    }
  };
  return (
    <Fragment>
      <ImageUploader
        withIcon={true}
        withPreview={true}
        label=""
        singleImage={true}
        buttonText="Seleccionar imagen"
        onChange={onDrop}
        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
        maxFileSize={1048576}
        fileSizeError="Archivo muy pesado"
        buttonClassName="btn btn-info"
      />
    </Fragment>
  );
};
export default UploadImage;
