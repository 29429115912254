import React from "react";
import { db } from "../../firebase";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import SpeciesGallery from "../../components/wiki/SpeciesGallery";
import Loader from "../../components/Loader/Loader";

import WikiModal from "../../components/common/WikiModal";
import AddGroupForm from "../../components/wiki/forms/AddGroupForm";
import AddSpeciesForm from "../../components/wiki/forms/AddSpeciesForm";
const WikiVetSpecies = (props) => {
  const {
    match: {
      params: { grupoID },
    },
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [species, setSpecies] = React.useState([]);
  const [group, setGroup] = React.useState();

  const [editGroup, setEditGroup] = React.useState(false);

  const [addSpecies, setAddSpecies] = React.useState(false);

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const speciesA = a.ORDEN.toUpperCase();
    const speciesB = b.ORDEN.toUpperCase();

    let comparison = 0;
    if (speciesA > speciesB) {
      comparison = 1;
    } else if (speciesA < speciesB) {
      comparison = -1;
    }
    return comparison;
  }

  const getSpecies = () => {
    setLoading(true);
    db.collection("ESPECIES")
      .where("grupoID", "==", grupoID)
      .get()
      .then((res) => {
        const species = [];
        res.forEach((item) => {
          species.push(item.data());
        });
        species.sort(compare);
        setSpecies(species);
        setLoading(false);
        console.log("especies obtenidas");
      });
  };

  const getGroup = () => {
    db.collection("grupos")
      .doc(grupoID)
      .get()
      .then((res) => {
        setGroup(res.data());
        console.log(res.data());
        getSpecies();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getGroup();
  }, []);

  if (loading && !group) {
    return <Loader />;
  } else {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Wiki de Vetlook"
            subtitle="Administrador Vetlook"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                    <h4>Selecciona una especie</h4>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6 col-sm-12 text-right">
                    <ButtonGroup>
                      <Button
                        outline
                        theme="info"
                        onClick={() => setEditGroup(!editGroup)}
                      >
                        Editar Grupo
                      </Button>
                      <Button
                        outline
                        theme="success"
                        onClick={() => setAddSpecies(!addSpecies)}
                      >
                        Agregar Especie
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <SpeciesGallery wikiSpecies={species} />
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
        <WikiModal
          title={"Editar grupo"}
          body={
            <AddGroupForm
              edit={true}
              group={group}
              close={() => setEditGroup(!editGroup)}
            />
          }
          view="groups"
          open={editGroup}
          close={() => {
            setEditGroup(!editGroup);
          }}
        />
        <WikiModal
          title={"Agregar nueva especie"}
          body={
            <AddSpeciesForm
              group={group}
              close={() => setAddSpecies(!addSpecies)}
              refresh={getSpecies}
            />
          }
          open={addSpecies}
          close={() => {
            setAddSpecies(!addSpecies);
          }}
        />
      </Container>
    );
  }
};

export default WikiVetSpecies;
